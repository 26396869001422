<script>
import {
  required,
  // email,
  //   minLength,
  // sameAs,
  //   maxLength,
  // minValue,
  // maxValue,
  // numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import tableData from "@/components/tablecomponent";
// import { LGeoJson } from "vue2-leaflet";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    tableData,
    // Switches,
    // NumberInputSpinner,*/
  },
  page: {
    title: "เพิ่มงานนอก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  computed: {
    /**
     * Total no. of records
     */
  },

  created() {
    this.getLocalData();
  },

  data() {
    return {
      submitformOS: false,

      title: "เพิ่มใบสั่งงานนอก",
      items: [
       {
          text: "บริการ",
        },
        {
          text: "สั่งงานนอก",
        },
        {
          text: "เพิ่มใบสั่งงานนอก",
        },
      ],
      stepOS: false,
      outsourcingId: "",
      serviceSelect: [],
      amount: [],
      dcPer: 0,
      dcPrice: 0,
      netPrice: 0,
      vatPer: 7,
      vatPrice: 0,

      loading: undefined,
      optionsSub: [],
      localDataBranch: [],
      localData: [],
      svId: "",
      roId: "",
      rowSv: [],
      rowRo: [],
      optionsService: [],
      roDate: "",

      rowService: [],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "osCode",
          label: "รหัสงานนอก",
        },
        {
          key: "nameTh",
          label: "ชื่องานนอก",
        },
        {
          key: "price",
          label: "ราคา/หน่วย",
        },
        {
          key: "amount",
          label: "จำนวน",
        },
        {
          key: "totalPrice",
          label: "รวม",
        },
        {
          key: "dcPer",
          label: "ส่วนลด(%)",
        },
        {
          key: "dcPrice",
          label: "ลดรวม",
        },
        {
          key: "netPrice",
          label: "หลังลด",
        },
      ],
      fieldsRO: [
        {
          key: "roCode",
          label: "เลขใบ RO",
        },
        {
          key: "roDate",
          label: "วันที่",
        },
        {
          key: "licensePlate",
          label: "ทะเบียน",
        },
        {
          key: "customerFullNameTh",
          label: "ชื่อลูกค้า",
        },
        {
          key: "grandTotalPrice",
          label: "ยอดสุทธิใบ RO",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      formOS: {
        branchId: "",
        supId: "",
        roId: "",
        outsourcingDate: "",
        note: "",
      },
    };
  },
  validations: {
    formOS: {
      branchId: {
        required,
      },
      supId: {
        required,
      },
      outsourcingDate: {
        required,
      },
      roId: {
        required,
      },
    },
  },

  methods: {
    onRowSelected(ro) {
      console.log(ro[0].outsourcingId);
      this.formOS.roId = ro[0];
      this.$refs.modalRO.hide();
    },
    getdataService() {
      useNetw
        .get("api/outsourcing/services", {
          params: {
            outsourcingId: this.outsourcingId,
          },
        })
        .then((response) => {
          console.log("OS in service", response);
          this.rowService = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    postService() {
      const osPost = new FormData();

      osPost.append("outsourcingId", this.outsourcingId);
      osPost.append("osId", this.formOS.serviceSelect.osId);
      osPost.append("amount", this.amount);
      osPost.append("pricePerOne", this.serviceSelect.price);
      osPost.append("totalPrice", this.serviceSelect.price * this.amount);
      osPost.append("dcPer", this.dcPer / 100);
      osPost.append(
        "dcPrice",
        this.serviceSelect.price * this.amount * (this.dcPer / 100)
      );
      osPost.append(
        "netPrice",
        this.serviceSelect.price * this.amount -
          this.serviceSelect.price * this.amount * (this.dcPer / 100)
      );
      osPost.append("vatPer", this.dcPer / 100);
      osPost.append(
        "vatPrice",
        this.serviceSelect.price * this.amount * (this.vatPer / 100)
      );
      osPost.append(
        "totalPrice",
        this.serviceSelect.price * this.amount +
          this.serviceSelect.price * this.amount * (this.vatPer / 100)
      );

      // ? head
      const totalPrice = this.rowService.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);

      osPost.append("totalPrice", totalPrice);

      useNetw
        .post("api/outsourcing/service/store", osPost)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    popupmodalAddOS(data) {
      console.log("select Services=>", data);
      this.$refs.modalAddService.show();
    },
    customLabelService({ osCode, nameTh }) {
      return `[${osCode}] - ${nameTh}`;
    },
    postBranchOS: function() {
      // this.overlayFlag = true;
      const osPost = new FormData();

      osPost.append("branchId", this.formOS.branchId.branchId);
      osPost.append("roId", this.formOS.roId.roId);
      osPost.append("outsourcingDate", this.formOS.outsourcingDate);
      osPost.append("note", this.formOS.note);
      osPost.append(
        "supId",
        this.formOS.supId.supId == undefined ? "" : this.formOS.supId.supId
      );

      useNetw
        .post("api/outsourcing/store", osPost)
        .then((response) => {
          //   this.$router.push({ name: "main-outsourcing" });
          //   console.log(response);
          this.outsourcingId = response.data.outsourcingId;
          // this.$router.push({name: 'edit-outsourcing', params: { outsourcingId: rowData.item.outsourcingId })
          // this.$router.push(`edit-outsourcing/${this.outsourcingId}`);
          this.$router.push({
            name: "edit-outsourcing",
            params: {
              outsourcingId: this.outsourcingId,
            },
          });
          // this.getdataService();

          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.branchId = user.branchId;
    },

    formOSSubmit() {
      this.$v.$touch();
      if (this.$v.formOS.$invalid) {
        this.submitformOS = true;
      } else {
        this.postBranchOS();
      }
    },

    getSup: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId: this.formOS.branchId.branchId,
          },
        })
        .then((response) => {
          this.optionsSub = response.data.data;
          this.getDataRo();
          // this.getDataSv();
          // this.getDataBranchService();
          // this.getDataRO();
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getDataBranchService: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/outsourcing/outsourcing-service", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.formOS.branchId.branchId],
          },
        })
        .then((response) => {
          this.optionsService = response.data.data;
          //   console.log("Service=>", this.optionsService);
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getDataSv() {
      useNetw
        .get("api/service", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.formOS.branchId.branchId],
          },
        })
        .then((response) => {
          this.rowSv = response.data.data;
          console.log("Sv=>", this.rowSv);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.isLoading = false;
        });
    },
    getDataRo() {
      useNetw
        .get("api/outsourcing/repair-orders", {
          params: {
            page: 1,
            perPage: 100,
            // svId: this.svId.svId,
            branchId: this.formOS.branchId.branchId,
            roDate: this.roDate,
          },
        })
        .then((response) => {
          this.rowRo = response.data.data;
          console.log("Ro=>", this.rowRo[1]);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-skeleton-wrapper :loading="loading"
          ><!--// !skeleton start-->
          <template #loading>
            <br />
            <b-skeleton animation="wave" width="10%"></b-skeleton>
            <b-skeleton animation="wave" width="15%"></b-skeleton>

            <br />
            <div class="row">
              <div class="col-md-2">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />
            <div class="row">
              <br />
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
              <div class="col-md-3">
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </div>
            </div>
            <br />

            <div class="card">
              <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: false, striped: true }"
              ></b-skeleton-table>
            </div>
          </template>
          <!--// !skeleton ends-->

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 my-auto"></div>
                <div class="col-md-6 text-end">
                  <router-link :to="{ name: 'main-outsourcing' }">
                    <button class="btn btn-secondary">กลับสู่หน้าหลัก</button>
                  </router-link>
                </div>
              </div>
              <hr />
              <form class="needs-validation" @submit.prevent="formOSSubmit()">
                <div class="row">
                  <div class="col-md-6">
                    <code>* </code><label for="branchId">สาขา</label>
                    <multiselect
                      v-model="formOS.branchId"
                      :options="localData"
                      label="nameTh"
                      @input="getSup"
                      :class="{
                        'is-invalid': submitformOS && $v.formOS.branchId.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitformOS && $v.formOS.branchId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formOS.branchId.required">{{
                        error
                      }}</span>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <code>* </code><label for="branchId">บริษัท</label>
                    <multiselect
                      v-model="formOS.supId"
                      :options="optionsSub"
                      label="nameTh"
                      :class="{
                        'is-invalid': submitformOS && $v.formOS.supId.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitformOS && $v.formOS.supId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formOS.supId.required">{{ error }}</span>
                    </div>
                  </div>
                </div>
                <!--//! End Rows -->

                <div class="row mt-3">
                  <!-- <div class="col-md-4">
                    <code>* </code><label for="branchId">เลขใบ SV</label>
                    <multiselect
                      v-model="svId"
                      :options="rowSv"
                      label="svCode"
                      @input="getDataRo"
                      :class="{
                        'is-invalid': submitformOS && $v.formOS.branchId.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitformOS && $v.formOS.branchId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formOS.branchId.required">{{
                        error
                      }}</span>
                    </div>
                  </div> -->

                  <div class="col-md-4">
                    <code>* </code><label for="branchId">เลขใบ RO</label>
                    <multiselect
                      v-model="formOS.roId"
                      :options="rowRo"
                      label="roCode"
                      :class="{
                        'is-invalid': submitformOS && $v.formOS.roId.$error,
                      }"
                    >
                    </multiselect>
                    <div
                      v-if="submitformOS && $v.formOS.roId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.formOS.roId.required">{{ error }}</span>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <label for="btnRO">&nbsp;</label><br />
                    <a class="btn btn-primary" @click="$refs.modalRO.show()"
                      ><i class="uil uil-search"></i> ค้นหาใบ RO</a
                    >
                  </div>

                  <div class="col-6 col-sm-6 col-lg-4">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="validation">วันที่</label>
                      <date-picker
                        v-model="formOS.outsourcingDate"
                        format="YYYY-MM-DD"
                        value-type="format"
                        :class="{
                          'is-invalid':
                            submitformOS && $v.formOS.outsourcingDate.$error,
                        }"
                      ></date-picker>
                      <div
                        v-if="submitformOS && $v.formOS.outsourcingDate.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formOS.outsourcingDate.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //!End row -->

                <div class="row mt-3">
                  <div class="col-md-6">
                    <label for="note">หมายเหตุ</label>
                    <b-form-textarea
                      v-model="formOS.note"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </div>
                </div>
                <div class="text-end">
                  <button type="submit" class="btn btn-success">บันทึก</button>
                </div>
                <hr />
              </form>
            </div>
          </div>
          <!-- //!end card -->
        </b-skeleton-wrapper>

        <div class="card" v-if="stepOS">
          <div class="card-body">
            <h5>เพิ่มข้อมูลงานนอก</h5>
            <hr />
            <div class="row">
              <tableData :fields="fields" :items="rowService">
                <template #cell(index)="showTB">
                  {{ showTB.index + 1 }}
                </template>
              </tableData>
            </div>
            <div class="row">
              <div class="col-md-4">
                <code>* </code
                ><label for="branchId">เลือกงานนอกเพื่อเพิ่ม</label>
                <multiselect
                  v-model="serviceSelect"
                  :options="optionsService"
                  :custom-label="customLabelService"
                  @input="popupmodalAddOS(serviceSelect)"
                >
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <!-- //!end card -->
      </div>
    </div>
    <b-modal
      title="เพิ่มงานนอก"
      hide-footer
      size="md"
      centered
      ref="modalAddService"
    >
      <div class="row">
        <div class="col-md-12">
          <h5>
            [ {{ serviceSelect.osCode }} ] - {{ serviceSelect.nameTh }} <br />
            ราคา : {{ serviceSelect.price }}
          </h5>
          <hr />
        </div>
        <div class="col-md-12">
          <label for="amount">จำนวน</label>
          <input type="number" v-model="amount" class="form-control" />
        </div>
        <div class="col-md-12">
          <label for="amount">%ส่วนลด</label>
          <input type="number" v-model="dcPer" class="form-control" />
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button class="btn btn-success" @click="postService">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      title="เลือกใบสั่งงานนอก"
      hide-footer
      size="lg"
      centered
      ref="modalRO"
    >
      <div class="row">
        <div class="col-md-6">
          <label for="branchId">สาขา</label>
          <multiselect
            v-model="formOS.branchId"
            :options="localData"
            label="nameTh"
            @input="getDataRo"
            :class="{
              'is-invalid': submitformOS && $v.formOS.branchId.$error,
            }"
          >
          </multiselect>
          <div
            v-if="submitformOS && $v.formOS.branchId.$error"
            class="invalid-tooltip"
          >
            <span v-if="!$v.formOS.branchId.required">{{ error }}</span>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-lg-4">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">วันที่</label>
            <date-picker
              v-model="roDate"
              format="YYYY-MM-DD"
              value-type="format"
            ></date-picker>
          </div>
        </div>
        <div class="col-md-2">
          <label for="btnRo">&nbsp;</label><br>
          <button class="btn btn-primary" @click="getDataRo"><i class="uil uil-search"></i>ค้นหา</button>
        </div>
      </div>

      <div class="row mt-3">
        <div class="table-responsive mb-0">
          <b-table
            :items="rowRo"
            :fields="fieldsRO"
            responsive="true"
            :per-page="100"
            :current-page="1"
            ref="selectableTable"
            selectable
            select-mode="single"
            @row-selected="onRowSelected"
          >
          </b-table>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-lg-12">
          <div class="text-end">
            <button class="btn btn-success">
              เพิ่ม
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
